import React, { Component, useState } from "react";
import { Suspense } from "react";
import {
  HashRouter,
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import { CAlert } from "@coreui/react";
import "./scss/style.scss";
import PrivateRoutes from "./views/Config/PrivateRoutes";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

function App() {
  console.log("PROCESS >> ", process);
  const [token, settoken] = useState();
  if (!token) {
    // return <Login settoken={setToken}/>
  }
  return (
    // {navigator.onLine ? (
    //   ""
    // ) : (
    //   <div className="mt-2">

    //     <CAlert color="info" closeButton>
    //       Internet Connection Lost!
    //     </CAlert>
    //   </div>
    // )}
    <Router>
      <Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <Login {...props} />}
          />
          <Route
            exact
            path="/register"
            name="Register Page"
            render={(props) => <Register {...props} />}
          />
          <Route
            exact
            path="/404"
            name="Page 404"
            render={(props) => <Page404 {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={(props) => <Page500 {...props} />}
          />
          <PrivateRoutes
            path="/"
            name="Home"
            render={(props) => <TheLayout {...props} />}
            c
          />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
