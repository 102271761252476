import React, { useContext } from "react";
import { HashRouter, BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

export default function PrivateRoutes({render: Component , ...rest }) {

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        localStorage.getItem("token") ? (
          <Component {...routeProps}/>
        ) : (
          <Redirect to={"/login"} />
        )
      }
    />
  );
}
